
import {map} from 'rxjs/operators';
import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';

import { SessionService } from 'src/app/shared/services/session.service';

@Pipe({ name: 'superUser' })
export class SuperUserPipe implements PipeTransform {
    constructor(
        private sessionService: SessionService
    ) { }

    public transform(value: any, args?: any): Observable<boolean> {
        return this.sessionService.userData().pipe(
            map(userData => userData.user.is_superuser));
    }
}
