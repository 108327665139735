import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Subscription } from 'rxjs';

import { AuthApiService } from 'src/app/core/services/auth-api.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: 'forgot-password.component.html'
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];

  public form: UntypedFormGroup;
  public error: string;
  public success: string = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthApiService,
    private fb: UntypedFormBuilder,
    private recaptchaV3Service: ReCaptchaV3Service,
  ) { }

  public ngOnInit(): void {
    this.form = this.fb.group({
      username: [null, [Validators.required, Validators.email]],
    });

    this.authService.lastLogin().then(username => {
      if (username) {
        this.form.patchValue({ username: atob(username) });
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  public onSubmit() {
    this.error = null;

    this.subscriptions.push(
      this.recaptchaV3Service.execute('requestChangePassword')
        .subscribe((token) => this.handleToken(token))
    );
  }

  public handleToken(token) {
    const value = this.form.value;

    this.subscriptions.push(
      this.authService.requestChangePassword(value.username, token).subscribe(ok => {
        this.success = 'Solicitação enviada com sucesso!';
        setTimeout(() => {
          const navigate = this.route.snapshot.queryParams['returnUrl'];
          this.router.navigateByUrl(!navigate ? '/' : navigate);
        }, 1000);

      }, err => {
        this.showError(err.error);
      })
    );
  }

  public showError(error: Error | string) {
    console.log(error);

    if (typeof (error) === 'string') {
      this.error = <string>error;
      return;
    }

    if (error['non_field_errors']) {
      this.error = error['non_field_errors'].join('<br>');
      return;
    }

    const err = <Error>error;
    this.error = err.message;
  }

  public goToLogin(): void {
    this.router.navigateByUrl('/');
  }
}
