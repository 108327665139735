import { Injectable } from "@angular/core";

import { showErrorInToastr } from "src/app/shared/utils";
import { ToastrMsgService } from "src/app/shared/services/toastr-msg.service";
import { WAApiService } from "./send-to-wa.api.service";
import { SendToWAModalService } from "./send-to-wa.modal.service";
import { WhatsappCreateModel } from "./send-to-wa.model";
import { ModalWAConfig } from "./send-to-wa.component";

export enum WAContentType {
  STRING = "string",
  MEDIA = "media",
  MEDIA_URL = "media_url",
}

export interface WAContent {
  // Base64 em string | string | url
  value: string;
  // Opcionais
  mimetype?: string;
  filename?: string;
  text?: string;
}

@Injectable({ providedIn: "root" })
export class WAService {
  constructor(
    private apiService: WAApiService,
    private modalService: SendToWAModalService,
    private toastr: ToastrMsgService
  ) { }

  sendToWA(phones: string[], content_type: WAContentType, content: WAContent) {
    const cfg: ModalWAConfig = {
      phones: phones,
      sessions: [],
      fileName: content.filename,
      text: content.text
    };

    this.apiService.getSessions().subscribe((data) => {
      cfg.sessions = data;

      this.modalService.show(cfg).then((data) => {
        const payload = new WhatsappCreateModel();
        payload.content_type = content_type;
        payload.content = content;
        payload.chat_dest = data.phones;
        payload.key = data.session.key;
        payload.send_in = null;

        if (data.text) {
          payload.content['text'] = data.text
        }

        this.apiService.enviar(payload).subscribe((resultData) => {
          this.toastr.success("Conteúdo será enviado em breve!");
        }, error => {
          showErrorInToastr(this.toastr, error);
        });
      });
    });
  }
}
