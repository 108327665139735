import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of as observableOf } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireDatabase } from '@angular/fire/compat/database';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  uid = this.afAuth.authState.pipe(
    map(authState => authState ? authState.uid : null)
  );
  token = this.afAuth.idToken.pipe(
    map(authState => authState ? authState : null)
  );
  email = this.afAuth.authState.pipe(
    map(authState => authState ? authState.email : null)
  );
  emailVerified = this.afAuth.authState.pipe(
    map(authState => authState ? authState.emailVerified : null)
  );
  photoUrl = this.afAuth.authState.pipe(
    map(authState => authState ? authState.photoURL : null)
  );
  phoneNumber = this.afAuth.authState.pipe(
    map(authState => authState ? authState.phoneNumber : null)
  );
  isAdmin: Observable<boolean> = this.uid.pipe(
    switchMap(uid => {
      if (!uid) {
        return observableOf(false);
      } else {
        return this.db.object<boolean>('app/users/admin/' + uid).valueChanges();
      }
    })
  );
  srvId: Observable<number> = this.uid.pipe(
    switchMap(uid => {
      if (!uid) {
        return observableOf(null);
      } else {
        return this.db.object<number>(`app/users/${uid}/srvId`).valueChanges();
      }
    })
  );
  firstName: Observable<string> = this.uid.pipe(
    switchMap(uid => {
      if (!uid) {
        return observableOf(null);
      } else {
        return this.db.object<string>(`app/users/${uid}/firstName`).valueChanges();
      }
    })
  );

  constructor(
    private router: Router,
    private afAuth: AngularFireAuth,
    private db: AngularFireDatabase
  ) { }

  isLogged(): boolean {
    return this.uid !== null;
  }

  hasProfile(): Observable<boolean> {
    return new Observable<boolean>(subscribe => {
      this.srvId.subscribe(dbId => {
        subscribe.next(dbId !== null);
        // subscribe.next(true);
        subscribe.complete();
      });
    });
  }

  login(email: string, senha: string): Promise<any> {
    return this.afAuth.signInWithEmailAndPassword(email, senha);
    // return this.afAuth.auth.signInWithPopup(new auth.GoogleAuthProvider()).then(data => {
    //     console.log(data);
    // }, error => {
    //     console.error(error);
    // });
  }

  logout(): void {
    this.afAuth.signOut().then(() => {
      this.uid = null;
      const returnUrl = this.router.url;
      this.router.navigate(['login'], {queryParams: {returnUrl}});
    }).catch((error) => {
      console.error(`Erro ao fazer logout: ${error}`);
    });
  }
}
