import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApplicationApiService } from 'src/app/shared/services/application-api.service';

import { WhatsappCreateModel } from 'src/app/shared/components/send-to-wa/send-to-wa.model';
import { ResponsePagination } from 'src/app/shared/utils';

const URL_BASE = '/api/whatsapp/';

@Injectable({providedIn: 'root'})
export class WAApiService extends ApplicationApiService {

  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }

  public getSessions(): Observable<any[]> {
    return this.http.get<ResponsePagination<any[]>>(`${URL_BASE}session/`, {params: {active: 'true'}}).pipe(
      map(res => res.results)
    );
  }

  public enviar(data: WhatsappCreateModel): Observable<any[]> {
    return this.http.post<any[]>(`${URL_BASE}send-message/`, data).pipe(
      map(res => res)
    );
  }
}
