import { Injectable, Injector } from '@angular/core';
import { of as observableOf, Observable } from 'rxjs';
import { share } from 'rxjs/operators';

import * as Sentry from '@sentry/angular';
// import { ApmService } from '@elastic/apm-rum-angular';

import { ApplicationApiService } from 'src/app/shared/services/application-api.service';
import { UserData } from 'src/app/shared/interfaces/user-data.interface';
import { CrossDomainStorageService } from 'src/app/core/services/cross-domain-storage.service';

export const TOKEN_NAME = 'token_v1';
export const UNIDADE_KEY = 'ultima_unidade_v1';
export const LAST_LOGIN = 'll_success';

@Injectable({
  providedIn: 'root',
})
export class SessionService extends ApplicationApiService {
  private _userData: UserData;
  private _getuserData: Observable<any>;

  constructor(
    private storageService: CrossDomainStorageService,
    // private apmService: ApmService,
    protected injector: Injector
  ) {
    super(injector);
    this.clear();
  }

  public clear() {
    this._userData = null;
    this._getuserData = null;
  }

  public get data(): UserData {
    if (!this._userData) {
      console.error('userData não disponivel');
    }
    return this._userData;
  }

  public lastLogin(): Promise<string> {
    return this.storageService.getItem(LAST_LOGIN);
  }

  public setLastLogin(username: string): Promise<boolean> {
    return this.storageService.setItem(LAST_LOGIN, btoa(username));
  }

  public userData(): Observable<UserData> {
    if (this._userData) {
      const userName = `${this._userData.user.full_name}: [${this._userData.unit.organization.trading_name}]`;

      Sentry.setUser({
        id: `${this._userData.user.id}`,
        username: this._userData.user.full_name,
        email: this._userData.user.email,
        org_name: this._userData.unit.organization.name,
        org_unit: this._userData.unit.name
      });
      // this.apmService.apm.setUserContext({
      //   username: userName,
      //   email: this._userData.user.email
      // })

      return observableOf(this._userData);
    }

    return new Observable<UserData>(observer => {
      this.storageService.preLoad([TOKEN_NAME, UNIDADE_KEY]).then(() => {

        if (this._getuserData === null) {
          this._getuserData = this.http.get('/api/accounts/user/data/').pipe(share());
        }

        this._getuserData.subscribe(res => {
          this._userData = res;
          observer.next(this._userData);
          observer.complete();
        }, err => {
          this._userData = null;
          this._getuserData = null;

          observer.error(err);
          observer.complete();
        });
      });
    });
  }
}
