import {of as observableOf,  Observable } from 'rxjs';
import { Pipe, PipeTransform } from '@angular/core';

import { PermissionService } from 'src/app/shared/services/permission.service';

@Pipe({ name: 'permission' })
export class PermissionPipe implements PipeTransform {

    constructor(
        private permissionSerice: PermissionService
    ) { }

    public transform(value: string[]): Observable<boolean> {

        if (!value || value.length === 0) {
            return observableOf(true);
        }

        return this.permissionSerice.hasPermission(value[0], value[1] || null);
    }
}
