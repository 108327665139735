import { Injectable } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';

import { ModalWAConfig, ModalWAResult, SendToWaComponent } from 'src/app/shared/components/send-to-wa/send-to-wa.component';

@Injectable({providedIn: 'root'})
export class SendToWAModalService {
  constructor(
    private modalService: BsModalService
  ) { }

  public show(config: ModalWAConfig): Promise<ModalWAResult> {
    const modalClass = 'modal-sm';
    return new Promise((resolve, reject) => {
      const modal = this.modalService.show(SendToWaComponent, { class: modalClass });
      modal.content.setOption(resolve, reject, config);
    });
  }
}
