<ngx-extended-pdf-viewer
  [height]="'90vh'"
  [src]="src"
  [customToolbar]="additionalButtons"
  [showToolbar]="showToolbar"
  [showBookmarkButton]="false"
  [showSidebarButton]="true"
  [showFindButton]="true"
  [showPagingButtons]="true"
  [showZoomButtons]="true"
  [showPresentationModeButton]="true"
  [showOpenFileButton]="false"
  [showPrintButton]="true"
  [showDownloadButton]="true"
  [showSecondaryToolbarButton]="true"
  [showRotateButton]="true"
  [showHandToolButton]="false"
  [showSpreadButton]="false"
  [showPropertiesButton]="false"
  [textLayer]="true"
  zoom="page-fit"
  (pageRendered)="onPageRendered($event)">
  >
</ngx-extended-pdf-viewer>

<ng-template #additionalButtons>
  <div id="toolbarViewer">
    <div id="toolbarViewerLeft">
      <pdf-toggle-sidebar></pdf-toggle-sidebar>
      <div class="toolbarButtonSpacer"></div>
      <pdf-paging-area></pdf-paging-area>
    </div>
    <pdf-zoom-toolbar></pdf-zoom-toolbar> <!-- toolbar viewer middle -->
    <div id="toolbarViewerRight">
      <pdf-presentation-mode></pdf-presentation-mode>
      <pdf-print></pdf-print>
      <button title="Enviar via Whatsapp"
        *ngIf="['whatsapp', 'send_file'] | permission | async"
        (click)="sendToWA()"
        style="height: 22px; width: 22px; margin: 0; position: relative; float: left; padding: 8px 6px 0; border: none; background: none;">
        <i class="fab fa-whatsapp"></i>
      </button>
      <pdf-download></pdf-download>
      <div class="verticalToolbarSeparator hiddenSmallView"></div>
      <pdf-toggle-secondary-toolbar></pdf-toggle-secondary-toolbar>
      <button title="Fechar"
        (click)="hide()"
        style="height: 22px; width: 22px; margin: 0; position: relative; float: left; padding: 7px 6px 0; border: none; background: none;">
        <i class="far fa-times-circle"></i>
      </button>
    </div>
  </div>
</ng-template>
