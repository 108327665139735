<div class="app-body">
  <main class="main d-flex align-items-center">
    <div class="container">
      <div class="row">
        <!-- <div class="col-md-8 mx-auto"> -->
        <div class="col-md-6 mx-auto">
          <div class="card-group">
            <div class="card p-4">
              <div class="card-body">
                <form [formGroup]="form" (submit)="onSubmit()">
                  <div class="row">
                    <div class="col-7">
                      <h1>Senha</h1>
                      <p class="text-muted">Recupere sua senha!</p>
                    </div>
                    <div class="col-5 text-right">
                      <img src="assets/img/brand/logo-ladder.png" style="height: 70px" alt="Logo" />
                    </div>
                  </div>
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="icon-user"></i></span>
                    </div>
                    <input type="text" class="form-control" placeholder="Usuário" autocomplete="username"
                      formControlName="username" required>
                  </div>
                  <div class="input-group mb-3">
                  </div>
                  <div class="alert alert-danger mb-3" *ngIf="!!error">{{ error }}</div>
                  <div class="alert alert-success mb-3" *ngIf="!!success">{{ success }}</div>

                  <div class="row mt-1">
                    <div class="col-4">
                      <button type="submit" class="btn btn-primary px-4"
                        [ngClass]="{'btn-secondary': form.invalid, 'btn-primary': form.valid}"
                        [disabled]="form.invalid">
                        Recuperar
                      </button>
                    </div>
                    <div class="col-8 text-right">
                      <button type="button" class="btn btn-link px-0" (click)="goToLogin()">Voltar ao Login</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <!-- <div class="card text-white bg-primary py-5 d-md-down-none" style="width:44%">
              <div class="card-body text-center">
                <div>
                  <h2>Crie sua conta</h2>
                  <p>Se você ainda não tem uma conta, cadastre-se agora para acesso ao sistema.</p>
                  <button type="button" class="btn btn-primary active mt-3"
                    [routerLink]="['/register']">Cadastre-se!</button>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </main>
</div>
