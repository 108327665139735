import { Injectable } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';

import { ModalPDFViewerConfig, PdfViewerComponent } from 'src/app/shared/components/pdf-viewer/pdf-viewer.component';

@Injectable()
export class ModalPDFViewerService {
  constructor(
    private modalService: BsModalService
  ) { }

  public show(config: ModalPDFViewerConfig): Promise<{ value: any, data: any }> {
    const modalClass = `modal-${config.modalClass || 'xl'}`;
    return new Promise((resolve, reject) => {
      const modal = this.modalService.show(PdfViewerComponent, { class: modalClass });
      modal.content.setOption(resolve, reject, config);
    });
  }
}
