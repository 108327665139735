import { Injectable, Injector } from "@angular/core";
import {
  HttpHeaders,
} from "@angular/common/http";

import { saveAs } from "file-saver";

import { ApplicationApiService } from "./application-api.service";
import { ModalPDFViewerService } from "src/app/shared/components/pdf-viewer/pdf-viewer.service";

function SafelyParseJSON(json) {
  try {
    return JSON.parse(json);
  } catch (e) {
    return;
  }
}

export interface SendTo {
  emails?: string[];
  waNumbers?: string[];
  text?: string;
}

@Injectable()
export class PDFService extends ApplicationApiService {
  constructor(
    protected injector: Injector,
    private pdfViewerService: ModalPDFViewerService
  ) {
    super(injector);
  }

  public get(
    url: string,
    search?: { [key: string]: any | any[] },
    fileName: string = "relatorio",
    sendTo: SendTo = null
  ): Promise<any> {
    const rep_type = (search ? search["rep_type"] : "pdf") || "pdf";
    // return this.request('GET', url, { params: this.createHttpParams(search), rep_type }, fileName);
    return this.getReport(
      "GET",
      url,
      { params: this.createHttpParams(search), rep_type, sendTo },
      fileName
    );
  }

  public post(
    url: string,
    body: any,
    fileName: string = "relatorio",
    sendTo: SendTo = null
  ): Promise<any> {
    // return this.request('POST', url, { body: JSON.stringify(body) }, fileName);
    return this.getReport(
      "POST",
      url,
      { body: JSON.stringify(body), sendTo },
      fileName
    );
  }

  private getReport(
    method: string,
    url: string,
    options: any,
    fileName: string = "relatorio"
  ): Promise<any> {
    const repType = (options ? options["rep_type"] : "pdf") || "pdf";
    const repName = `${fileName}.${repType}`;

    let waNumbers: string[];
    let waText: string = null;
    const sendTo = options ? options["sendTo"] : null;
    if (sendTo) {
      // Verifica se há números de WhatsApp para envio do arquivo
      waNumbers = sendTo["waNumbers"] || [];

      // Verifica se há texto para enviar
      waText = sendTo["text"];
    }

    // monta container
    const modal = document.createElement("div");
    modal.style["position"] = "fixed";
    modal.style["z-index"] = "2500";
    modal.style["width"] = "100%";
    modal.style["height"] = "100%";
    modal.style["background-color"] = "rgba(0, 0, 0, 0.8)";
    modal.style["display"] = "flex";
    modal.style["justify-content"] = "center";
    modal.style["align-items"] = "center";

    // monta texto
    const text = document.createElement("div");
    text.innerHTML = "Aguarde gerando impressão...";
    text.style["color"] = "white";
    text.style["font-size"] = "1.5rem";
    text.style["flex"] = "1 0 auto";
    text.style["text-align"] = "center";

    // Coloca itens na tela
    modal.appendChild(text);
    document.body.appendChild(modal);

    options["responseType"] = "blob";
    options["headers"] = new HttpHeaders({
      Accept: `application/${repType}, application/json`,
      "content-type":
        method === "POST" ? "application/json" : `application/${repType}`,
    });

    return new Promise<any>((resolve, reject) => {
      return this.http
        .request(method, url, options)
        .pipe()
        .subscribe(
          (resp) => {
            try {
              document.body.removeChild(modal);

              // const ua = navigator.userAgent.toLowerCase();
              // const isAndroid = ua.indexOf('mobile') > -1;

              // const blobURL = URL.createObjectURL(blob);

              // console.log(resp);

              // var reader = new FileReader();
              // reader.readAsDataURL(resp.body);
              // reader.onloadend = function() {
              //   var base64data = reader.result;
              //   console.log(base64data);
              // }

              const blobObj = new Blob([resp], { type: "application/pdf" });

              if (repType !== "pdf") {
                const blobURL = URL.createObjectURL(blobObj);
                saveAs(blobURL, repName);
              } else {
                this.pdfViewerService.show({
                  src: blobObj,
                  filename: repName,
                  title: fileName,
                  waNumbers,
                  waText,
                });
              }

              // if (isAndroid || rep_type !== 'pdf') {
              //   saveAs(blobURL, repName);
              // } else {
              //   printJS({ printable: blobURL, type: 'pdf', showModal: true, documentTitle: repName });
              // }

              resolve({ ok: true });
            } catch (e) {
              console.log("catch", e);
              resolve({ ok: false });
            }
          },
          async (err) => {
            console.log("err", await err.error.text());
            document.body.removeChild(modal);
            reject({
              ok: false,
              error: SafelyParseJSON(await err.error.text()),
            });

            const fr = new FileReader();
            // Ao terminar de ler o retorno, devolve o erro
            fr.onload = () =>
              reject({ ok: false, error: SafelyParseJSON(fr.result) });
            fr.readAsText(err.error);
          }
        );
    });
  }
}
