export const InsertAtCursor = (textarea, value) => {
    if (window.navigator.userAgent.indexOf('Edge') > -1) {
        // Microsoft Edge
        const startPos = textarea.selectionStart;
        const startText = textarea.value.substring(0, startPos);
        const endText = textarea.value.substring(textarea.selectionEnd, textarea.value.length);

        return `${startText}${value}${endText}`;
    } else if (textarea.selectionStart || textarea.selectionStart === '0') {
        // MOZILLA and others
        const startPos = textarea.selectionStart;
        const startText = textarea.value.substring(0, startPos);
        const endText = textarea.value.substring(textarea.selectionEnd, textarea.value.length);

        return `${startText}${value}${endText}`;
    } else {
        return `${textarea.value}${value}`;
    }
};


export const capitalize = (text: string) => {
    return text.replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
};

export const replaceText = (text: string, from: string, to: string): string => {
  return text.split(from).join(to);
}


export function blobToBase64(blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      if (reader.result) {
        resolve(reader.result.toString());
      } else {
        reject(new Error("Erro ao converter Blob para Base64."));
      }
    };
    reader.onerror = () => {
      reject(new Error("Erro ao ler o Blob."));
    };
    reader.readAsDataURL(blob); // Lê o blob como uma URL base64
  });
}
