import {
  LoginComponent, RegisterComponent,
  OrganizationsComponent, ChangePasswordComponent,
  JoinComponent, AppRegisterComponent,
  ForgotPasswordComponent
} from 'src/app/core/views';

import { AuthGuard } from 'src/app/core/guards';
import { Routes } from "@angular/router";

export const baseRoutes: Routes = [
  {
    path: 'organizations', pathMatch: 'full', data: { title: 'Organizações' },
    component: OrganizationsComponent, canActivate: [AuthGuard]
  },
  {
    path: 'login', pathMatch: 'full', data: { title: 'Login' },
    component: LoginComponent,
  },
  {
    path: 'register/:code', pathMatch: 'full', data: { title: 'Cadastre-se' },
    component: RegisterComponent,
  },
  {
    path: 'app-register', pathMatch: 'full', data: { title: 'Cadastre-se' },
    component: AppRegisterComponent,
  },
  {
    path: 'join/:code', pathMatch: 'full', data: { title: 'Confirmação' },
    component: JoinComponent
  },
  {
    path: 'change-password/:code', pathMatch: 'full', data: { title: 'Alterar Senha' },
    component: ChangePasswordComponent
  },
  {
    path: 'forgot-password', pathMatch: 'full', data: { title: 'Esqueceu sua Senha?' },
    component: ForgotPasswordComponent
  },
];
