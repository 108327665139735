import { Pipe, PipeTransform } from '@angular/core';
import { RenameCaptionService } from 'src/app/shared/services/rename-caption.service';

@Pipe({ name: 'renameCaption' })
export class RenameCaptionPipe implements PipeTransform {

    constructor(
        private renameCaptionService: RenameCaptionService
    ) {}

    public transform(value: any, args?: any): string {
        return this.renameCaptionService.getCaption(value);
    }
}
