import { Pipe, PipeTransform } from '@angular/core';

import { Moment } from 'src/app/shared/utils';

@Pipe({ name: 'moment' })
export class MomentPipe implements PipeTransform {
    transform(value: any, args?: any): any {
        if (value && args) {
            const date = Moment(value);

            if (date.isValid()) {
                return date.format(args);
            }
        }

        return '';
    }
}
