import { Component, OnInit } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { FormValidators } from "src/app/shared/utils";

export interface WaSession {
  key: string;
  name: string;
  phone_number: string;
}

export interface ModalWAConfig {
  phones: string[];
  sessions: WaSession[];
  fileName: string;
  text?: string;
}

export interface ModalWAResult {
  phones: string[];
  session: WaSession;
  text?: string;
}

@Component({
  selector: "app-send-to-wa",
  templateUrl: "./send-to-wa.component.html",
  styleUrls: ["./send-to-wa.component.scss"],
})
export class SendToWaComponent implements OnInit {
  private config: ModalWAConfig;
  private resolve: any;
  private reject: any;

  public sessions: WaSession[];
  public selectedSession: WaSession = null;
  public phone: string = "";
  public fileName: string = null;
  public text: string = null;

  constructor(public bsModalRef: BsModalRef) {}

  ngOnInit(): void {}

  setOption(resolve, reject, config: ModalWAConfig): void {
    this.config = config;
    this.resolve = resolve;
    this.reject = reject;

    this.sessions = this.config.sessions;

    if (this.sessions && this.sessions.length > 0) {
      this.selectedSession = this.sessions[0];
    }

    this.fileName = this.config.fileName;
    this.text = this.config.text;

    if (this.config.phones && this.config.phones.length > 0) {
      this.phone = this.config.phones[0].replace(' ','');
    }
  }

  setSession(event): void {
    console.log(event);
  }

  valid(): boolean {
    return !!this.phone && FormValidators.doValidateCelPhone(this.phone);
  }

  done(event) {
    this.resolve({ phones: [this.phone], session: this.selectedSession, text: this.text });
    this.bsModalRef.hide();
  }
}
