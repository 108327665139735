import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { AuthApiService } from 'src/app/core/services';
import { httpErrorMsgs } from 'src/app/core/utils/htp-error';

@Component({
  selector: 'app-register',
  templateUrl: 'register.component.html'
})
export class RegisterComponent implements OnInit {

  public form: UntypedFormGroup;
  public error: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthApiService,
    private fb: UntypedFormBuilder,
  ) { }

  public ngOnInit(): void {
    this.form = this.fb.group({
      email: [null, [Validators.required, Validators.email]],
      invite_code: [this.route.snapshot.params['code'], [Validators.required]],
      first_name: [null, [Validators.required]],
      last_name: [''],
      password: [null, [Validators.required]],
      confirm_password: [null, [Validators.required]]
    });
  }

  public onSubmit() {
    this.error = null;

    const value = this.form.value;
    this.authService.register(value).subscribe(() => {
      this.authService.login(value.email, value.password).subscribe(ok => {
        this.router.navigateByUrl('/');
      }, err => {
        this.setError(err);
      });
    }, err => {
      this.setError(err);
    });
  }

  public setError(error: Error | string) {
    if (typeof (error) === 'string') {
      this.error = <string>error;
      return;
    }

    const htpErros = httpErrorMsgs(<Error>error, {
      email: 'E-mail',
      password: 'Senha',
      confirm_password: 'Confirmação da Senha',
      invite_code: 'Código de Convite'
    });

    this.error = '';
    htpErros.forEach(err => {
      const msg = err.title ? `${err.title}: ${err.msg}` : err.msg;
      this.error += msg + '\n';
    });
  }

}
